import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CommonAccountConstant } from "./CommonAccountConstant";

const COMMON_ACCOUNT_CONSTANT = CommonAccountConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_COMMON_ACCOUNT_COL_DEF: any[] = [

    {
        headerName: COMMON_ACCOUNT_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
            headerName: COMMON_ACCOUNT_CONSTANT.COMMON_AC_TYPE,
            field: "commonAcType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 190,
            },
    {
        headerName: COMMON_ACCOUNT_CONSTANT.CONSORTIUM_CODE,
        field: "consortiumCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.VESSEL_CODE,
        field: "vesselCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.VOYAGE_CODE,
        field: "voyageCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.SERVICE_CODE,
        field: "serviceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.SOA,
        field: "soa",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 100,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.ETD,
        field: "etd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        dataType : "date",
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.STATUS,
        field: "cntrStatus",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.OPS_LINE,
        field: "owner",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 120,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.CHARGE_IND,
        field: "chargeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 140,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
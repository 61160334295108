import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CommonAccountConstant } from "./CommonAccountConstant";

const COMMON_ACCOUNT_CONSTANT = CommonAccountConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_COMMON_ACCOUNT_COMPANY_COL_DEF: any[] = [

    {
        headerName: COMMON_ACCOUNT_CONSTANT.BILL_TO_COMPANY,
        field: "billToCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
            headerName: COMMON_ACCOUNT_CONSTANT.CHARGE_ON_COMPANY,
            field: "chargeOnCompany",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
        headerName: COMMON_ACCOUNT_CONSTANT.PERCENTAGE,
        field: "percentage",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
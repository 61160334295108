import { SelectionChangedEvent } from "ag-grid-community";
import { CommonAccountCompanyEntity } from "domain/entity/CommonAccount/CommonAccountCompanyEntity";
import { INITIAL_COMMON_ACCOUNT_COMPANY_COL_DEF } from "presentation/constant/CommonAccount/CommonAccountCompanyColumnDefinition";
import { CommonAccountConstant } from "presentation/constant/CommonAccount/CommonAccountConstant";
import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { CommonAccountModel } from "presentation/model/CommonAccount/CommonAccountModel";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable, SliderPanel } from "veronica-ui-component/dist/component/core";
import CommonAccountCompanyEditPanel from "../RightPanel/CommonAccountCompanyEditPanel";
const CommonAccountCompanyTablePanel = ({ commonAccountState }: { commonAccountState: CommonAccountModel }) => {
    const gridRef: any = useRef(null);
    const { tempCompanyRows, selectedCompanyRows, isShowCompanyAddPanel, isShowCompanyEditPanel, isEdit, isAdd } = commonAccountState;
    const commonAccountVM = useCommonAccountVM();
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        commonAccountVM.updateSelectedCompanyRows(selectedRows);
    }, [commonAccountVM])


    const handleRowDoubleClick = useCallback((entity: CommonAccountCompanyEntity) => {
        if (!isEdit && !isAdd) return;
        commonAccountVM.onCompanyRowDoubleClick(entity);
    }, [commonAccountVM, isAdd, isEdit])

    const handleAddClick = useCallback(() => {

        commonAccountVM.onCompanyAddRowClick();
    }, [commonAccountVM])

    const handDelete = useCallback(() => {
        if (selectedCompanyRows.length === 0) {
            messageBarVM.showError("Please select a record.");
        }
        commonAccountVM.onCompanyDtlDeleteClick(selectedCompanyRows);
    }, [commonAccountVM, messageBarVM, selectedCompanyRows]);

    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        ((isAdd || isEdit) && !(isShowCompanyAddPanel || isShowCompanyEditPanel)) && hdrBtns.push({
            id: 'onDeleteButton',
            icon: 'Icon-trash',
            title: 'Delete'
        })

        return hdrBtns
    }, [isAdd, isEdit, isShowCompanyAddPanel, isShowCompanyEditPanel])

    const memoCommonAccountCompanyTable = useMemo(() => {

        return (
            <TableWrapper>
                <HPHTable
                    id='common-account-company-table'
                    isNewColumnSetting={true}
                    headerLabel={CommonAccountConstant.Title.COMPANY_TITLE}
                    columns={INITIAL_COMMON_ACCOUNT_COMPANY_COL_DEF}
                    data={tempCompanyRows ?? []}
                    showPaginator={false}
                    editable={false}
                    headerActionButtons={memoBtns}
                    onDeleteButton={handDelete}
                    showAddIcon={((isAdd || isEdit) && !(isShowCompanyAddPanel || isShowCompanyEditPanel))}
                    onAddClick={handleAddClick}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    showUploadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    isRowHighligted={true}
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any, entity: CommonAccountCompanyEntity) => handleRowDoubleClick(entity)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                    ref={gridRef}
                /></TableWrapper>
        );
    }, [tempCompanyRows, memoBtns, handDelete, isAdd, isEdit, isShowCompanyAddPanel, isShowCompanyEditPanel, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    // return <><TableWrapper>
    //         {memoCommonAccountCompanyTable}
    //     </TableWrapper>
    //     </>;

    return <>
        <div className={`main-comp-wrapper${(isShowCompanyAddPanel || isShowCompanyEditPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={(isShowCompanyAddPanel || isShowCompanyEditPanel) ? "40%" : "100%"}
                rightSectionWidth={(isShowCompanyAddPanel || isShowCompanyEditPanel) ? "60%" : "0%"}
                leftChildren={memoCommonAccountCompanyTable}
                rightChildren={<CommonAccountCompanyEditPanel />}
            />
        </div>
    </>
}

export default memo(CommonAccountCompanyTablePanel);

import { SelectionChangedEvent } from "ag-grid-community";
import { CommonAccountEntity } from "domain/entity/CommonAccount/CommonAccountEntity";
import _ from "lodash";
import { INITIAL_COMMON_ACCOUNT_COL_DEF } from "presentation/constant/CommonAccount/CommonAccountColumnDefinition";
import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCommonAccountTracked } from "presentation/store/CommonAccount/CommonAccountProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const CommonAccountTablePanel = () => {
    const gridRef: any = useRef(null);
    const [commonAccountState] = useCommonAccountTracked();
    // const {commonAccountList,updatedRows} = commonAccountState;
    const commonAccountVM = useCommonAccountVM();
    const messageBarVM = useMessageBarVM(); 

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CommonAccountEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ initialAllRows, setInitialAllRows ] = useState<boolean>(true);

    
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        setOnTableSelectionClicked(true);
        commonAccountVM.updateSelectedRows(selectedRows);
      }, [commonAccountVM])

    // const handleRowDrag = useCallback((e: any, updatedRows: CommonAccountEntity[], movedIndex: number, overIndex: number)=>{
    //     commonAccountVM.onRowDrag(updatedRows);
    // },[commonAccountVM])


    const handleRowDoubleClick = useCallback((entity: CommonAccountEntity) => {
        // if(updatedRows.length > 0){
        //     messageBarVM.showError("Modified data has not been saved.Please save or refersh the data first."); 
        //     return;
        // }
        commonAccountVM.onRowDoubleClick(entity);
  }, [commonAccountVM])

  const getAllRows = useCallback(() => {
    return allRows;
}, [allRows]);

const getSelectedRows = useCallback(() => {
    return commonAccountState.selectedRows;
}, [commonAccountState.selectedRows]);

const getShowMoveCursor = useCallback(() => {
    return showMoveCursor;
}, [showMoveCursor]);
//for right click menu with move after end

useEffect(() => {
    const columnDefs = (INITIAL_COMMON_ACCOUNT_COL_DEF.slice());

    if (!commonAccountState.selectedRows || commonAccountState.selectedRows.length <= 0) {
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    }
    changeCursor("common-account-table", getShowMoveCursor());
});

useEffect(() => {
    if (!onTableSelectionClicked) return;
    gridRef?.current?.gridRef.current.api?.deselectAll();
    changeCursor("common-account-table", getShowMoveCursor());
}, [getShowMoveCursor, onTableSelectionClicked]);

useEffect(() => {
    const handleRightClickMenu = (event: any) => {
        if (_.isEmpty(commonAccountState.selectedRows) || !allowUpdate) {
            return;
        }
        event.preventDefault();
        rightClickRef.current.show(event);
    };
    document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

    return () => {
        document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
    };
}, [messageBarVM, rightClickRef, allowUpdate, commonAccountState.selectedRows]);

// for right click menu with move after begin
const onRefreshRow = useCallback((newAllRows: any[]) => {
    setAllRows(newAllRows);
    commonAccountVM.updateSelectedRows([]);
    setShowMoveCursor(true);
}, [commonAccountVM]);

const onMoveCancel = useCallback(() => {
    setInitialAllRows(true);
    setAllRows(commonAccountState.commonAccountList);
    commonAccountVM.updateSelectedRows([]);
    setShowMoveCursor(false);
}, [commonAccountState.commonAccountList, commonAccountVM]);

const onSaveMove = useCallback(async (newAllRows: any[]) => {
    if (_.isEmpty(newAllRows)) return;

    setIsLoading(true);
    
    commonAccountVM.onApply(newAllRows).then((res) => {
        if (!res || !res.success) {
            messageBarVM.showWarining('Move failed.');
            setInitialAllRows(true);
            setIsLoading(false);

            commonAccountVM.updateSelectedRows([]);
            setShowMoveCursor(false);
        } else {
            commonAccountVM.searchAllCommonAccountList().then((data) => {
                setInitialAllRows(true);
                setIsLoading(false);

                commonAccountVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            }).catch((e) => {
                setInitialAllRows(true);
                setIsLoading(false);

                commonAccountVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            })
        }
    })
}, [messageBarVM, commonAccountVM]);

    const memoCommonAccountTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
        <HPHTable
            id='common-account-table'
            isNewColumnSetting={true}
            columns={INITIAL_COMMON_ACCOUNT_COL_DEF}
            data={allRows ?? []}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: CommonAccountEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            // isRowDrag={true}
            // onRowDragged={handleRowDrag}
            rowSelection={showMoveCursor ? "single" : "multiple"} 
            onBodyScroll={(e: any) => changeCursor("common-account-table", getShowMoveCursor())}
        />
        </div>
        </>
        );
    },[allRows, allowUpdate, getAllRows, getSelectedRows, getShowMoveCursor, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && commonAccountState.commonAccountList && !_.isEmpty(commonAccountState.commonAccountList)) {
            setAllRows(commonAccountState.commonAccountList.map((commonAccountEntity, index) => ({
                ...commonAccountEntity,
                index: index || 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, commonAccountState.commonAccountList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoCommonAccountTable}
        </TableWrapper>
        </>;
}

export default memo(CommonAccountTablePanel);

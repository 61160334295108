import { CommonAccountConstant } from "presentation/constant/CommonAccount/CommonAccountConstant";
import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CommonAccountTitleBar:React.FC = () => {
    const COMMON_ACCOUNT_CONSTANT= CommonAccountConstant.Title;
    const commonAccountVM = useCommonAccountVM();
    // const [commonAccountState] = useCommonAccountTracked();
    // const [isLoading, setIsLoading] = useState(false);
    // const {updatedRows} = commonAccountState;
    // const messageBarVM = useMessageBarVM();  
    
    const handleAdd = useCallback(() => {
        commonAccountVM.onAddClick();
    }, [commonAccountVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await commonAccountVM.onApply(updatedRows);
    //     if(!!!res || !res.success){
    //         setIsLoading(false);
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         setIsLoading(false);
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await commonAccountVM.searchAllCommonAccountList();
    //     }
    // }, [commonAccountVM, messageBarVM, updatedRows]);

    
    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     commonAccountVM.searchAllCommonAccountList().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[commonAccountVM]);

    // const isApplyDisable = () => {
    //     if(updatedRows.length !== 0) return false
    //     return true;
    // }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{COMMON_ACCOUNT_CONSTANT.TITLE}</HeaderTitle>
        {/* {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> } */}
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/>
            <div className="add-seperator"/> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            {/* <div className="add-seperator"/>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(CommonAccountTitleBar);
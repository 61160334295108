import { CommonAccountConstant, commonAccountRequiredFieldList } from "presentation/constant/CommonAccount/CommonAccountConstant";
import { commonAcTypeDropdownOption, statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { CommonAccountModel } from "presentation/model/CommonAccount/CommonAccountModel";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, HPHInputMask, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CommonAccountEditPanel = ({commonAccountState}:{commonAccountState:CommonAccountModel}) => {
    const COMMON_ACCOUNT_CONSTANT= CommonAccountConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    // const [commonAccountState] = useCommonAccountTracked();
    const commonAccountVM = useCommonAccountVM();
    const {currentEditRow,isAdd,isEdit,isShowCompanyAddPanel,isShowCompanyEditPanel} = commonAccountState;
    const messageBarVM = useMessageBarVM();
    
    const hasDuplicateChargeAndBillToCompanies = useCallback(() => {
        const duplicates = currentEditRow.companyDetails.filter((item, index, self) => 
            self.some((otherItem, otherIndex) => 
                otherIndex !== index &&
                item.chargeOnCompany === otherItem.chargeOnCompany &&
                item.billToCompany === otherItem.billToCompany
            )
        );
    
        if (duplicates.length > 0) {
            return true;
        }
    
        return false;
    }, [currentEditRow.companyDetails]);

    const handleSave = useCallback(async () => {
        if(!currentEditRow.commonAcType){
            messageBarVM.showError("Common A/C Type is mandatory.");
            return;
        }
        if(!currentEditRow.activeInd){
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        if(hasDuplicateChargeAndBillToCompanies()){
            messageBarVM.showError("Duplicate record found, please check the key: Common Account Detail Code.");
            return;
        }
        if(currentEditRow.companyDetails.reduce((sum, item) => sum + (item.percentage??0), 0) !== 100){
            messageBarVM.showError("The sum of percentage is not equal to 100%.");
            return;
        }
        setIsLoading(true);
        const data = await commonAccountVM.onSave(currentEditRow);
        if(data && data.success){
            messageBarVM.showSuccess("Save Successfully.");
            commonAccountVM.searchAllCommonAccountList().then((data) => {            
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }else{
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [commonAccountVM, currentEditRow, hasDuplicateChargeAndBillToCompanies, messageBarVM]);

    const handClose = useCallback(() => {
        commonAccountVM.onCloseEidtPanel();
    }, [commonAccountVM]);


    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType?commonAccountState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType]:[];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, commonAccountState.dynamicOptions.subChargeTypeDropdownOptions])



    const memoCommonACType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.commonAcType??''}
                fieldValue={currentEditRow?.commonAcType}
                fieldLabel={COMMON_ACCOUNT_CONSTANT.COMMON_AC_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'commonAcType'}
                maxLength={60}
                options={commonAcTypeDropdownOption}
                requiredFieldList={commonAccountRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.commonAcType, COMMON_ACCOUNT_CONSTANT.COMMON_AC_TYPE, commonAccountVM])
    
    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
            <HPHInputMask
                width='270px'
                disabled={!(isAdd||isEdit)}
                label={COMMON_ACCOUNT_CONSTANT.CO_VSL_VOY}
                value={{
                    co: currentEditRow.consortiumCode ?? '',
                    vsl: currentEditRow.vesselCode ?? '',
                    voy: currentEditRow.voyageCode ?? ''
                }}
                optional={true}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => commonAccountVM.onCoVslVoyChange(e, {
                    co: 'consortiumCode',
                    vsl: 'vesselCode',
                    voy: 'voyageCode'
                })}/>
        </div>
    , [isAdd, isEdit, COMMON_ACCOUNT_CONSTANT.CO_VSL_VOY, currentEditRow.consortiumCode, currentEditRow.vesselCode, currentEditRow.voyageCode, commonAccountVM])
    
    const memoServiceCodeList = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow.serviceCodeList?.join(',')}
                fieldValue={currentEditRow?.serviceCodeList}
                fieldLabel={COMMON_ACCOUNT_CONSTANT.SERVICE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCodeList'}
                maxLength={60}
                isMultipleDropDown={true}
                options={commonAccountState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={commonAccountRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.serviceCodeList, COMMON_ACCOUNT_CONSTANT.SERVICE_CODE, commonAccountState.dynamicOptions.serviceCodeDropdownOptions, commonAccountVM])
    
    const memoOwnerList = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow.ownerList?.join(',')}
                fieldValue={currentEditRow?.ownerList}
                fieldLabel={COMMON_ACCOUNT_CONSTANT.OPS_LINE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'ownerList'}
                maxLength={60}
                isMultipleDropDown={true}
                options={commonAccountState.dynamicOptions.opsLineDropdownOptions}
                requiredFieldList={commonAccountRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.ownerList, COMMON_ACCOUNT_CONSTANT.OPS_LINE, commonAccountState.dynamicOptions.opsLineDropdownOptions, commonAccountVM])
    
    const memoCntrStatusList = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd||isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow.cntrStatusList?.join(',')}
                fieldValue={currentEditRow?.cntrStatusList}
                fieldLabel={COMMON_ACCOUNT_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatusList'}
                maxLength={60}
                isMultipleDropDown={true}
                options={statusDropdownOptions}
                requiredFieldList={commonAccountRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.cntrStatusList, COMMON_ACCOUNT_CONSTANT.STATUS, commonAccountVM])
    
        const memoChargeType = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={!(isAdd||isEdit)}
                    isShowOptional={true}
                    readOnlyValue={currentEditRow?.chargeType || ''}
                    fieldValue={currentEditRow?.chargeType}
                    fieldLabel={COMMON_ACCOUNT_CONSTANT.CHARGE_TYPE}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'chargeType'}
                    maxLength={60}
                    options={commonAccountState.dynamicOptions.chargeTypeDropdownOptions}
                    requiredFieldList={commonAccountRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [COMMON_ACCOUNT_CONSTANT.CHARGE_TYPE, commonAccountState.dynamicOptions.chargeTypeDropdownOptions, commonAccountVM, currentEditRow?.chargeType, isAdd, isEdit])
        
        const memoSubChargeType = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={!(isAdd||isEdit)}
                    isShowOptional={true}
                    readOnlyValue={currentEditRow?.subChargeType || ''}
                    fieldValue={currentEditRow?.subChargeType}
                    fieldLabel={COMMON_ACCOUNT_CONSTANT.SUB_CHARGE_TYPE}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'subChargeType'}
                    maxLength={60}
                    options={memoSubChgTypeOptions}
                    requiredFieldList={commonAccountRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [COMMON_ACCOUNT_CONSTANT.SUB_CHARGE_TYPE, commonAccountVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])
     
        
        
        const memoSoa = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={!(isAdd||isEdit)}
                    isShowOptional={true}
                    readOnlyValue={currentEditRow?.soa || ''}
                    fieldValue={currentEditRow?.soa}
                    fieldLabel={COMMON_ACCOUNT_CONSTANT.SOA}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'soa'}
                    maxLength={60}
                    options={commonAccountState.dynamicOptions.soaDropdownOptions}
                    requiredFieldList={commonAccountRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [isAdd, isEdit, currentEditRow?.soa, COMMON_ACCOUNT_CONSTANT.SOA, commonAccountState.dynamicOptions.soaDropdownOptions, commonAccountVM])
        
        const memoChargeInd = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={!(isAdd||isEdit)}
                    isShowOptional={true}
                    readOnlyValue={currentEditRow?.chargeInd || ''}
                    fieldValue={currentEditRow?.chargeInd}
                    fieldLabel={COMMON_ACCOUNT_CONSTANT.CHARGE_IND}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'chargeInd'}
                    maxLength={60}
                    options={commonAccountState.dynamicOptions.chargeIndDropdownOptions}
                    requiredFieldList={commonAccountRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        commonAccountVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [isAdd, isEdit, currentEditRow?.chargeInd, COMMON_ACCOUNT_CONSTANT.CHARGE_IND, commonAccountState.dynamicOptions.chargeIndDropdownOptions, commonAccountVM])
        
            const memoEtd = useMemo(() =>
                <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
                    <DatePickerComponent
                            disabled={!(isAdd||isEdit)}
                            label={COMMON_ACCOUNT_CONSTANT.ETD}
                            width="270px"
                            optional={true}
                            date={currentEditRow?.etd}
                            fieldName="etd"
                            errorMessage={""}
                            onDateChange={commonAccountVM.onHeaderFieldChange}/>
                </div>
                , [COMMON_ACCOUNT_CONSTANT.ETD, commonAccountVM.onHeaderFieldChange, currentEditRow?.etd, isAdd, isEdit])
            

                
        const memoActiveInd = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "270px", marginBottom: "24px" }}>
                <NbisCommonField 
                    fieldKey={"activeInd"} 
                    requiredFieldList={commonAccountRequiredFieldList} 
                    fieldLabel={COMMON_ACCOUNT_CONSTANT.ACTIVE_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEditRow?.activeInd==='Y'} 
                    disabled={!(isAdd||isEdit)} 
                    onChange={(e) => commonAccountVM.onCheckboxChange(e.checked, "activeInd")} 
                />
            </div>
            , [COMMON_ACCOUNT_CONSTANT.ACTIVE_IND, currentEditRow?.activeInd, isAdd, isEdit, commonAccountVM])

            const handleEdit = useCallback(() => {
                commonAccountVM.onEditClick();
            }, [commonAccountVM]);
        
            const handleReset = useCallback(() => {
                commonAccountVM.onResetClick(isAdd);
            }, [commonAccountVM, isAdd]);
    

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />} 
                <Breadcrumb> 
                    <HPHBreadcrumb breadcrumbData={[{title: CommonAccountConstant.Title.TITLE}, 
                        { title:  CommonAccountConstant.Title.TITLE }]}onCurrentClick={()=>{}}> 
                    </HPHBreadcrumb> 
                </Breadcrumb> 
                <StyledAction>
                    <>
                    {!(isAdd||isEdit)&&<IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                    {(isAdd||isEdit)&&<IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                    
                    <IconButton fileName="Icon-cross" size="medium" disabled={(isShowCompanyAddPanel||isShowCompanyEditPanel)} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                    {(isAdd||isEdit)&&<IconButton fileName="Icon-tick" size="medium" disabled={(isShowCompanyAddPanel||isShowCompanyEditPanel)} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}> 
                <div className="flex-row-auto"> 
                    <div className={'flex-row-item'}> 
                        <div className={'im-flex-row'}> 
                            <div className={'flex-row-item-stretch'}> 
                            <SidebarTitle>
                                {isAdd&&"New Common Account"}
                                {!isAdd&&(currentEditRow.priority+"/"+currentEditRow.commonAcType)}
                            </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>} 
                            </div> 
                        </div>

                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoCommonACType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCoVslVoy}
                            {memoEtd}
                            {memoSoa}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoServiceCodeList}
                            {memoOwnerList}
                            {memoCntrStatusList}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeInd}
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(CommonAccountEditPanel);
